export const Countries = [
    { "id": "Europe", "name": "Europe" },
    { "id": "India", "name": "India" },
    { "id": "Brazil", "name": "Brazil" },
    { "id": "UnitedArabEmirates", "name": "UnitedArabEmirates" },
    { "id": "UnitedKingdom", "name": "UnitedKingdom" },
    { "id": "UnitedStates", "name": "UnitedStates" },
    { "id": "Mexico", "name": "Mexico" },
    { "id": "Singapore", "name": "Singapore" },
    { "id": "Argentina", "name": "Argentina" },
    { "id": "Colombia", "name": "Colombia" },
    { "id": "Canada", "name": "Canada" },
    { "id": "Australia", "name": "Australia" },
    { "id": "SouthKorea", "name": "SouthKorea" },
    { "id": "SaudiArabia", "name": "SaudiArabia" },
    { "id": "Qatar", "name": "Qatar" },
    { "id": "Pakistan", "name": "Pakistan" },
    { "id": "Switzerland", "name": "Switzerland" },
    { "id": "Denmark", "name": "Denmark" },
    { "id": "Sweden", "name": "Sweden" },
    { "id": "Uruguay", "name": "Uruguay" },
    { "id": "Ukraine", "name": "Ukraine" },
    { "id": "Turkey", "name": "Turkey" },
    { "id": "Indonesia", "name": "Indonesia" },
    { "id": "Thailand", "name": "Thailand" },
    { "id": "Philippines", "name": "Philippines" },
    { "id": "Malaysia", "name": "Malaysia" },
    { "id": "China", "name": "China" },
    { "id": "Venezuela", "name": "Venezuela" },
    { "id": "HongKong", "name": "HongKong" },
    { "id": "Peru", "name": "Peru" },
    { "id": "Chile", "name": "Chile" },
    { "id": "CostaRica", "name": "CostaRica" },
    { "id": "DominicanRepublic", "name": "DominicanRepublic" },
    { "id": "Salvador", "name": "Salvador" },
    { "id": "Japan", "name": "Japan" },
    { "id": "Poland", "name": "Poland" },
];

